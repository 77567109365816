<style scoped>
</style>

<template>
  <div class="medicine-form">
    <fm-form label-width="130px" label-align="right">
      <fm-form-item label="名称">
        <fm-input-new placeholder="请输入名称" v-model="newData.name"/>
      </fm-form-item>
      <fm-form-item label="类型">
        <fm-radio-group v-model="newData.type">
          <fm-radio label="中医" value="chinese"></fm-radio>
          <fm-radio label="西医" value="western"></fm-radio>
        </fm-radio-group>
      </fm-form-item>
      <fm-form-item label="症状">
        <Select
          placeholder="请输入症状"
          v-model="newData.symptom"
          filterable
          multiple
          allow-create
          @on-create="handlerSymptomCreate">
          <Option v-for="item in symptomList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </fm-form-item>
      <fm-form-item label="关联病症">
        <fm-tag @click.native="showRelationDisease(item)" style="cursor: pointer;margin-right: 10px;" closable @close="delRelationDisease(item)" v-for="item in relationDiseaseList" :key="item.id">{{item.name}}</fm-tag>
        <fm-btn @click="status.relationDisease = true">添加关联病症</fm-btn>
      </fm-form-item>
      <fm-form-item label="可用药方" v-loadingx="loading.tmp">
        <fm-tag @click.native="showTmp(item)" style="cursor: pointer;margin-right: 10px;" closable @close="delTmp(item)" v-for="item in tmpList" :key="item.id">{{item.pname}}</fm-tag>
        <fm-btn @click="status.tmp = true">添加药方</fm-btn>
      </fm-form-item>
      <fm-form-item label="病症描述">
        <fm-input-new type="textarea" v-model="newData.description"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="辅助检查">
        <fm-input-new type="textarea" v-model="newData.examinationAide"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="体格检查">
        <fm-input-new type="textarea" v-model="newData.examinationPhysique"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="诊断及鉴别诊断">
        <fm-input-new type="textarea" v-model="newData.diagnosis"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="治疗方案">
        <fm-input-new type="textarea" v-model="newData.treatmentPlan"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="辩证要点">
        <fm-input-new type="textarea" v-model="newData.dialecticalPoint"></fm-input-new>
      </fm-form-item>
      <fm-form-item>
        <fm-btn long @click="submit">确定</fm-btn>
      </fm-form-item>
    </fm-form>
    <fm-modal width="1000px" v-model="status.relationDisease" title="病症查询">
      <disease-page
        style="height: 70vh;min-height: 450px;position: relative;"
        @choose="chooseRelationDisease"
        choose
        v-if="status.relationDisease"
      />
    </fm-modal>
    <fm-modal width="1000px" v-model="status.tmp" title="添加药方">
      <tmp-content v-if="status.tmp" style="height: 70vh;position: relative;" is-use @chooseTmp="chooseTmp" />
    </fm-modal>
    <fm-modal width="1000px" v-model="status.tmpDetails" title="药方详情">
      <template v-if="chooseTmpData">
        <fm-form>
          <fm-form-item label="名称：">
            {{chooseTmpData.name}}
          </fm-form-item>
          <fm-form-item label="功效：">
            {{chooseTmpData.effect}}
          </fm-form-item>
          <fm-form-item label="症状：">
            {{chooseTmpData.treatment}}
          </fm-form-item>
        </fm-form>
      </template>
    </fm-modal>
    <fm-modal width="1000px" v-model="status.relationDiseaseDetails" title="病症详情">
      <template v-if="chooseRelationDiseaseData">
        <fm-form>
          <fm-form-item label="名称：">
            {{chooseRelationDiseaseData.name}}
          </fm-form-item>
          <fm-form-item label="类型：">
            {{chooseRelationDiseaseData.type === 'western' ? '西医病症' : '中医病症'}}
          </fm-form-item>
          <fm-form-item label="症状：">
            {{chooseRelationDiseaseData.symptom}}
          </fm-form-item>
          <fm-form-item label="病症描述：">
            {{chooseRelationDiseaseData.description}}
          </fm-form-item>
          <fm-form-item label="辅助检查：">
            {{chooseRelationDiseaseData.examinationAide}}
          </fm-form-item>
          <fm-form-item label="体格检查：">
            {{chooseRelationDiseaseData.examinationPhysique}}
          </fm-form-item>
          <fm-form-item label="诊断及鉴别诊断：">
            {{chooseRelationDiseaseData.diagnosis}}
          </fm-form-item>
          <fm-form-item label="治疗方案：">
            {{chooseRelationDiseaseData.treatmentPlan}}
          </fm-form-item>
          <fm-form-item label="辩证要点：">
            {{chooseRelationDiseaseData.dialecticalPoint}}
          </fm-form-item>
        </fm-form>
      </template>
    </fm-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import TmpContent from '@/views/prescription/tmp/tmpContent.vue'
import { prescriptionTmpRequest, diseaseRequest } from '@/api'
import DiseasePage from './index.vue'
import { Select, Option } from 'view-design'

Vue.component('Select', Select)
Vue.component('Option', Option)

function getDefaultSymptomList (items) {
  return items.concat(['发热', '头痛', '咳嗽', '咳痰', '呼吸困难', '腹痛', '腹泻', '腹胀', '胸痛', '心悸', '腰痛', '关节痛', '尿频', '尿急', '皮疹', '喷嚏', '干咳', '流脓涕'].filter(v => {
    return items.findIndex(({value}) => {
      return value === v
    }) === -1
  }).map(v => ({label: v, value: v})))
}

function getDefault () {
  return {
    type: 'chinese',
    symptom: [],
    prescriptionTmpIds: [],
    relationDiseaseIds: []
  }
}

function getData (data) {
  return data ? JSON.parse(JSON.stringify(data), function (key, value) {
    if (['prescriptionTmpIds', 'relationDiseaseIds'].includes(key) && !Array.isArray(value)) {
      return (value || '').split(',').filter(v => v !== '').map(v => Number(v))
    } else if (key === 'symptom') {
      return value && !Array.isArray(value) ? value.split(/[,，、；;\s]/).filter(v => v) : value
    } else {
      return value
    }
  }) : getDefault()
}

export default {
  components: { TmpContent },
  props: {
    data: { type: Object, default: null }
  },
  data () {
    const newData = getData(this.data)
    return {
      symptomList: getDefaultSymptomList((newData.symptom ? newData.symptom.map(v => {
        return { label: v, value: v }
      }) : [])),
      chooseTmpData: null,
      chooseRelationDiseaseData: null,
      tmpList: [],
      relationDiseaseList: [],
      newData: newData,
      status: {
        tmp: false,
        tmpDetails: false,
        relationDisease: false,
        relationDiseaseDetails: false
      },
      loading: {
        tmp: false,
        relationDisease: false
      }
    }
  },
  computed: {
    tmpIds () {
      return this.newData.prescriptionTmpIds
    },
    relationDiseaseIds () {
      return this.newData.relationDiseaseIds
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.newData = getData(this.data)
        this.symptomList = getDefaultSymptomList(this.newData.symptom ? this.newData.symptom.map(v => {
            return { label: v, value: v }
          }) : [])
        this.loadRelationDiseaseList()
        this.loadTmpList()
      }
    }
  },
  methods: {
    handlerSymptomCreate (val) {
      this.symptomList.concat(val.split(/[,，、；;\s]/).filter(v => {
        return v && this.symptomList.findIndex((value) => v === value) === -1
      }).map(v => {
        return { value: v, label: v }
      }))
    },
    submit () {
      this.$emit('submit', JSON.parse(JSON.stringify(this.newData), function (key, value) {
        if (['relationDiseaseIds', 'prescriptionTmpIds'].includes(key) && Array.isArray(value)) {
          return value.join(',')
        } else if (key === 'symptom') {
          return value ? value.join(',') : null
        } else {
          return value
        }
      }))
    },
    showRelationDisease (item) {
      this.chooseRelationDiseaseData = item
      this.status.relationDiseaseDetails = true
    },
    async loadRelationDiseaseList () {
      if (this.relationDiseaseIds.length && this.newData.id) {
        this.loading.relationDisease = true
        this.relationDiseaseList = await diseaseRequest.get({ startDiseaseId: this.newData.id })
        this.loading.relationDisease = false
      } else {
        this.relationDiseaseList = []
      }
    },
    delRelationDisease (disease) {
      let index = this.relationDiseaseList.findIndex(v => v.id === disease.id)
      if (index > -1) {
        this.relationDiseaseList.splice(index, 1)
        index = this.newData.relationDiseaseIds.findIndex(v => v === disease.id)
        index > -1 && this.newData.relationDiseaseIds.splice(index, 1)
      }
    },
    chooseRelationDisease (disease) {
      if (!this.newData.relationDiseaseIds.includes(disease.id)) {
        this.newData.relationDiseaseIds.push(disease.id)
        this.relationDiseaseList.push(disease)
      }
    },
    showTmp (item) {
      this.chooseTmpData = item
      this.status.tmpDetails = true
    },
    async loadTmpList () {
      if (this.tmpIds.length) {
        this.loading.tmp = true
        const res = await prescriptionTmpRequest.get({ ids: this.tmpIds.join(','), pageNum: 1, pageSize: 100 })
        this.tmpList = res.data
        this.loading.tmp = false
      } else {
        this.tmpList = []
      }
    },
    delTmp (tmp) {
      let index = this.tmpList.findIndex(v => v.id === tmp.id)
      if (index > -1) {
        this.tmpList.splice(index, 1)
        index = this.newData.prescriptionTmpIds.findIndex(v => v === tmp.id)
        index > -1 && this.newData.prescriptionTmpIds.splice(index, 1)
      }
    },
    chooseTmp (tmp) {
      if (!this.newData.prescriptionTmpIds.includes(tmp.id)) {
        this.newData.prescriptionTmpIds.push(tmp.id)
        this.tmpList.push(tmp)
      }
    }
  },
  mounted () {
    // 特殊处理，当page嵌套form，form再去嵌套page时
    // 刚import的page是undefined，无法通过components选项注册
    // 仅当挂载后，组件才可用
    Vue.component('DiseasePage', DiseasePage)
    this.loadRelationDiseaseList()
    this.loadTmpList()
  }
}
</script>

<style scoped lang="less"></style>

<template>
  <PageBase
    :title="choose ? '病症查询' : '病症管理'"
    :tabs="types"
    @tab-switch="onTabSwitch"
    :page-actions="pageActions"
    @page-action="pageAction"
    @page-change="onPageChange"
    :dataList="datas"
    :table-columns="tableColumns"
    :table-actions="tableActions"
    @table-action="tableAction"
    :filters="filters"
    @search="searchChange"
    :loading="loading.load"
    :page-num="pageNum"
    :total="total"
    :page-size="pageSize"
    @page-size-change="onPageSizeChange"
  >
    <template slot="modal" v-if="!choose">
      <fm-modal width="1000px" v-model="status.relation" title="对应病症症">
        <PageBase
          style="height: 70vh;min-height: 450px;position: relative;"
          v-if="status.relation"
          :loading="loading.relation"
          :dataList="relationList"
          :table-columns="relationTableColumns"
          :table-actions="tableActions"
          @table-action="tableAction"
        />
      </fm-modal>
      <fm-modal :value="newData !== null" width="80vw" :title="((newData && newData.id) ? '修改' : '新增') + '病症'" @cancel="newData = null" :maskClosable="false">
        <DiseaseForm v-if="mounted" :data="newData" @submit="submit" />
      </fm-modal>
    </template>
  </PageBase>
</template>

<script>
import { diseaseRequest } from '@/api'
import DiseaseForm from './form'
import PageBase from '@/components/page/base'

export default {
  name: 'DiseasePage',
  components: { DiseaseForm, PageBase },
  props: {
    choose: { type: Boolean, default: false }
  },
  data () {
    return {
      type: 'all',
      mounted: false,
      types: [
        { label: '全部', value: 'all' },
        { label: '西医', value: 'western' },
        { label: '中医', value: 'chinese' }
      ],
      loading: {
        load: false,
        relation: false
      },
      status: {
        relation: false
      },
      chooseData: null,
      newData: null,
      datas: [],
      relationList: [],
      search: {},
      pageSize: 10,
      pageNum: 1,
      total: 0
    }
  },
  computed: {
    relationTableColumns () {
      return this.tableColumns.filter(v => !['relationDiseaseIds'].includes(v.field))
    },
    tableColumns () {
      const ellipsis = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        flex: 1
      }
      return [
        { title: '名称', field: 'name', width: 100, fixed: 'left' },
        {
          title: '类型', field: 'type', render: (h, row) => {
            return h('div', row.type === 'western' ? '西医' : '中医')
          }
        },
        { title: '症状', field: 'symptom' },
        {
          title: '病症描述', field: 'description', render: (h, row) => {
            return h('div', {
              style: ellipsis,
              on: {
                click: () => {
                  this.$dialog.info({title: '病症描述', content: row.description})
                }
              }
            }, row.description || '-')
          }
        },
        {
          title: '辅助检查', field: 'examinationAide', render: (h, row) => {
            return h('div', {
              style: ellipsis,
              on: {
                click: () => {
                  this.$dialog.info({title: '辅助检查', content: row.examinationAide})
                }
              }
            }, row.examinationAide || '-')
          }
        },
        {
          title: '体格检查', field: 'examinationPhysique', render: (h, row) => {
            return h('div', {
              style: ellipsis,
              on: {
                click: () => {
                  this.$dialog.info({title: '体格检查', content: row.examinationPhysique})
                }
              }
            }, row.examinationPhysique || '-')
          }
        },
        {
          title: '诊断及鉴别诊断', field: 'diagnosis', render: (h, row) => {
            return h('div', {
              style: ellipsis,
              on: {
                click: () => {
                  this.$dialog.info({title: '诊断及鉴别诊断', content: row.diagnosis})
                }
              }
            }, row.diagnosis || '-')
          }
        },
        {
          title: '治疗方案', field: 'treatmentPlan', render: (h, row) => {
            return h('div', {
              style: ellipsis,
              on: {
                click: () => {
                  this.$dialog.info({title: '治疗方案', content: row.treatmentPlan})
                }
              }
            }, row.treatmentPlan || '-')
          }
        },
        {
          title: '辩证要点', field: 'dialecticalPoint', render: (h, row) => {
            return h('div', {
              style: ellipsis,
              on: {
                click: () => {
                  this.$dialog.info({title: '辩证要点', content: row.dialecticalPoint})
                }
              }
            }, row.dialecticalPoint || '-')
          }
        },
        {
          title: '对应症', field: 'relationDiseaseIds', render: (h, row) => {
            if (row.relationDiseaseIds) {
              return h('fm-btn', {
                props: {
                  text: true,
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.loadRelation(row)
                  }
                }
              }, '查看(' + row.relationDiseaseIds.split(',').filter(v => v).length + ')')
            } else {
              return h('div', '-')
            }
          }
        },
        { title: '操作', field: 'actions', slot: 'actions', fixed: 'right' }
      ].filter(v => this.choose ? !['relationDiseaseIds'].includes(v.field) : true)
    },
    tableActions () {
      return this.choose ? [
        { key: 'choose', label: '选取' }
      ] : [
        { key: 'update', label: '修改' },
        { key: 'del', label: '删除' }
      ]
    },
    pageActions () {
      return this.choose ? [] : [{label: '新增病症', value: 'add'}]
    },
    filters () {
      return [
        { label: '症状', field: 'symptom', show: true }
      ]
    },
    dataList () {
      return this.datas
    }
  },
  watch: {
    type () {
      this.loadData()
    }
  },
  methods: {
    async loadRelation (data) {
      this.chooseData = data
      this.status.relation = true
      this.loading.relation = true
      this.relationList = await diseaseRequest.get({
        startDiseaseId: data.id
      })
      this.loading.relation = false
    },
    onTabSwitch (type) {
      this.type = type
    },
    pageAction (key) {
      if (key === 'add') {
        this.newData = { type: this.type === 'all' ? 'chinese' : this.type, relationDiseaseIds: [], prescriptionTmpIds: [] }
      }
    },
    searchChange (data) {
      this.search = data
      this.loadData()
    },
    tableAction ({action, data}) {
      if (action === 'update') {
        this.newData = data
      } else if (action === 'del') {
        this.del(data)
      } else if (action === 'choose') {
        this.$emit('choose', data)
      }
    },
    async del (data) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + data.name + '吗?'})
      if (result) {
        await diseaseRequest.del(data.id)
        this.$notice.success('已删除')
        this.loadData()
      }
    },
    async submit (data) {
      if (data.id) {
        await diseaseRequest.update(data.id, data)
        this.$notice.success('更新成功')
      } else {
        await diseaseRequest.add(data)
        this.$notice.success('添加成功')
      }
      this.loadData()
      this.newData = null
    },
    onPageChange (num) {
      this.pageNum = num
      this.loadData()
    },
    onPageSizeChange (size) {
      this.pageNum = 1
      this.pageSize = size
      this.loadData()
    },
    async loadData () {
      this.loading.load = true
      const res = await diseaseRequest.get(Object.assign({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: this.type === 'all' ? undefined : this.type
      }, this.search))
      this.datas = res.data
      this.total = res.total
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData()
    this.$nextTick(() => console.log('page mounted'), this.mounted = true)
  }
}
</script>
